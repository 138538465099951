body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  font-family: "Inter", sans-serif;
}

#myBtnExtension:hover {
  transform: translate(-15px, 0);
  -webkit-transform: translate(-15px, 0);
  -moz-transform: translate(-15px, 0);
  -o-transform: translate(-15px, 0);
  -ms-transform: translate(-15px, 0);
}
